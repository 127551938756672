import Link from "next/link";
import { getEntityImage, trackEvent } from "lib/basic";
import { useAppContext } from "./../context/state";
import Image from "next/image";

export interface Props {
    label?: any;
    color?: any;
    trapezeImage?: any;
    articles?: any;
    seeallLink?: any;
    track?: string;
    trackAll?: string;
}

export default function ShortArticleZone({
    label,
    color,
    trapezeImage,
    articles,
    seeallLink = "/",
    track,
    trackAll,
}: Props): JSX.Element {
    const AppContext = useAppContext();

    return (
        <div
            className="ltx-ShortGridZone"
            style={{
                backgroundColor: color,
                backgroundImage: `url(${trapezeImage})`,
            }}
        >
            <div className="ltx_Contaner txt_HomePageTitle text-center mb-8">
                {label}
            </div>
            <div className="ltx_Container flex flex-row">
                {articles &&
                    articles.map((article: any) => (
                        <Link
                            href={article.parsed_url}
                            key={article.id}
                            passHref
                        >
                            <a
                                onClick={() => {
                                    if (track) {
                                        trackEvent(track, article.slug);
                                    }
                                }}
                            >
                                <div>
                                    <div className="ltx_ArticleBox">
                                        {/* <div
                                        className="ltx_Image"
                                        style={{
                                            backgroundImage: `url(${getEntityImage(
                                                article,
                                                "square_image_a",
                                                "thumbnail"
                                            )})`,
                                        }}
                                    /> */}
                                        <Image
                                            // alt={AppContext.defaultAlt}
                                            alt={article.title}
                                            src={getEntityImage(
                                                article,
                                                "square_image",
                                                "thumbnail"
                                            )}
                                            width={244}
                                            height={244}
                                            className="ltx_Image"
                                        />
                                        <div>
                                            <div className="ltx_Label">
                                                {article.section?.name}
                                            </div>
                                            <div className="ltx_Title">
                                                {article.title}
                                            </div>
                                            <div className="ltx_ArrowLink">
                                                <i />
                                                Read More
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </Link>
                    ))}
            </div>
            <div className="ltx_Container">
                <Link href={seeallLink} passHref>
                    <a
                        className="ltx_ArrowLinkRed"
                        onClick={() => {
                            if (trackAll) {
                                trackEvent(trackAll);
                            }
                        }}
                    >
                        SEE MORE
                    </a>
                </Link>
            </div>
        </div>
    );
}
