import Link from "next/link";
import { getEntityImage, trackEvent } from "lib/basic";
import { useAppContext } from "context/state";
import Image from "next/image";

const MAINARTICLE_ANNOUNCE_MINCHARS = 1;

export interface Props {
    color?: any;
    labelColor?: any;
    label?: any;
    mainArticle?: any;
    leftArticles?: any;
    rightArticles?: any;
}

export default function ZoneThreeColumns({
    color,
    labelColor,
    mainArticle,
    leftArticles,
    rightArticles,
}: Props): JSX.Element {
    const AppContext = useAppContext();

    return (
        <div
            className="ltx-ZoneThreeColumns"
            style={{ backgroundColor: color }}
        >
            <div>
                {leftArticles &&
                    leftArticles.map((article: any, articleIndex: number) => (
                        <Link
                            href={article.parsed_url}
                            key={articleIndex}
                            passHref
                        >
                            <a
                                onClick={() => {
                                    trackEvent(
                                        "HOMEPAGE_LEFT_ARTICLE",
                                        articleIndex + 1
                                    );
                                }}
                            >
                                <div className="ltx_zonethreecols_LeftArticle ltx_FlushLeft">
                                    <div
                                        className="ltx_Image lazy"
                                        style={{
                                            backgroundImage: `url(${getEntityImage(
                                                article,
                                                "image",
                                                "thumbnail",
                                                true
                                            )})`,
                                        }}
                                    />
                                    {/* <Image
                                    alt={AppContext.defaultAlt}
                                    className="ltx_Image"
                                    src={
                                        article &&
                                        getEntityImage(
                                            article,
                                            "image",
                                            "thumbnail"
                                        )
                                    }
                                    width={224}
                                    height={138}
                                /> */}
                                    <div className="ltx_Label">
                                        {article.section?.name}
                                    </div>
                                    <div className="ltx_Title">
                                        {article.title}
                                    </div>
                                    <div className="ltx_Announce">
                                        {article.announce}
                                    </div>
                                </div>
                            </a>
                        </Link>
                    ))}
            </div>
            <div>
                <Link
                    href={
                        mainArticle && mainArticle.link ? mainArticle.link : "/"
                    }
                    passHref
                >
                    <a
                        onClick={() => {
                            trackEvent("HOMEPAGE_TOP_ARTICLE");
                        }}
                    >
                        <div>
                            <div
                                className="ltx_zonethreecols_Image lazy"
                                style={{
                                    backgroundImage: `url(${
                                        mainArticle && mainArticle.image
                                    })`,
                                }}
                            />

                            <div
                                className="ltx_zonethreecols_Label"
                                style={{ backgroundColor: labelColor }}
                            >
                                {mainArticle && mainArticle.label}
                            </div>
                            <div className="txt_Header1 w-80 mx-auto text-center">
                                {mainArticle && mainArticle.title}
                            </div>
                            {mainArticle &&
                            mainArticle.announce.length >=
                                MAINARTICLE_ANNOUNCE_MINCHARS ? (
                                <div className="txt_BodyCopy1 w-5/6 mx-auto mt-4 text-center">
                                    {mainArticle && mainArticle.announce}
                                </div>
                            ) : null}

                            {mainArticle &&
                            mainArticle.announce.length <
                                MAINARTICLE_ANNOUNCE_MINCHARS ? (
                                <div className="txt_BodyCopy1 w-5/6 mx-auto mt-4 text-center">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                mainArticle &&
                                                mainArticle.extra,
                                        }}
                                    ></div>
                                </div>
                            ) : null}

                            <div className="ltx_ArrowLink mt-6 mb-3">
                                <i />
                                Read More
                            </div>
                        </div>
                    </a>
                </Link>
            </div>
            <div>
                {rightArticles &&
                    rightArticles.map((article: any, articleIndex: number) => (
                        <Link
                            href={article.parsed_url}
                            key={article.id}
                            passHref
                        >
                            <a
                                onClick={() => {
                                    trackEvent(
                                        "HOMEPAGE_RIGHT_ARTICLE",
                                        articleIndex + 1
                                    );
                                }}
                            >
                                <div className="ltx_zonethreecols_RightArticle ltx_FlushLeft">
                                    <div className="ltx_Label">
                                        {article.section?.name}
                                    </div>
                                    <div className="ltx_Title">
                                        {article.title}
                                    </div>
                                    <div className="ltx_Announce">
                                        {article.announce}
                                    </div>
                                    <div className="ltx_ArrowLink">
                                        <i />
                                    </div>
                                </div>
                            </a>
                        </Link>
                    ))}
            </div>
        </div>
    );
}
