import type { NextPage } from "next";
import { fetchAPI } from "lib/api";
import { getEntityImage, parseArticle } from "lib/basic";
import { useEffect, useState, useCallback } from "react";
import styles from "../styles/Home.module.scss";
import ZoneThreeColumns from "@/components/zonethreecolumns";
import BigArticleZone from "@/components/bigarticlezone";
import GridZone from "@/components/gridzone";
import ShortGridZone from "@/components/shortgridzone";
import { useAppContext } from "./../context/state";
import Layout from "@/components/layout";
import { isMobile } from "react-device-detect";

const MOBILE_SCROLL_TRESHOLD = 50;

declare global {
    interface Window {
        mainLazyLoad: any;
        Leanplum?: any;
    }
}

const Home: NextPage = ({
    res_featuredArticles,
    res_homepageCoverArticles,
    res_coverArticles,
    res_discoverArticles,
    res_libraryArticles,
    res_communityArticles,
    res_quote,
}: any) => {
    const [featuredArticles, setFeatiredArticles] = useState<any[]>([]);
    const [homepagecoverArticles, setHomepageCoverArticles] = useState<any[]>(
        []
    );
    const [coverArticles, setCoverArticles] = useState<any[]>([]);
    const [libraryArticles, setLibraryArticles] = useState<any[]>([]);
    const [communityArticles, setCommunityArticles] = useState<any[]>([]);
    const [discoverArticles, setDiscoverArticles] = useState<any[]>([]);
    const [quote, setQuote] = useState<any>(null);

    const AppContext = useAppContext();

    useEffect(() => {
        // console.info("useEffect res_featuredArticles", res_featuredArticles);
        // console.info(
        //     "useEffect res_homepageCoverArticles",
        //     res_homepageCoverArticles
        // );
        // console.info("useEffect res_coverArticles", res_coverArticles);
        // console.info("useEffect res_libraryArticles", res_libraryArticles);
        // console.info("useEffect res_communityArticles", res_communityArticles);
        // console.info("useEffect res_quote", res_quote);

        if (res_featuredArticles) {
            // Parsing
            res_featuredArticles.map((article: any) => {
                article = parseArticle(article, AppContext.variant);
            });
            setFeatiredArticles(res_featuredArticles);
        }
        if (res_homepageCoverArticles) {
            // Parsing
            res_homepageCoverArticles.map((article: any) => {
                article = parseArticle(article, AppContext.variant);
            });
            setHomepageCoverArticles(res_homepageCoverArticles);
        }
        if (res_coverArticles) {
            // Parsing
            res_coverArticles.map((article: any) => {
                article = parseArticle(article, AppContext.variant);
            });
            setCoverArticles(res_coverArticles);
        }
        if (res_discoverArticles) {
            // Parsing
            res_discoverArticles.map((article: any) => {
                article = parseArticle(article, AppContext.variant);
            });
            setDiscoverArticles(res_discoverArticles);
        }
        if (res_libraryArticles) {
            // Parsing
            res_libraryArticles.map((article: any) => {
                article = parseArticle(article, AppContext.variant);
            });
            setLibraryArticles(res_libraryArticles);
        }
        if (res_communityArticles) {
            // Parsing
            res_communityArticles.map((article: any) => {
                article = parseArticle(article, AppContext.variant);
            });
            setCommunityArticles(res_communityArticles);
        }
        if (res_communityArticles) {
            res_communityArticles.map((article: any) => {
                article = parseArticle(article, AppContext.variant);
            });
            setCommunityArticles(res_communityArticles);
        }
        if (res_quote) {
            setQuote(res_quote);
        }
    }, [
        res_featuredArticles,
        res_homepageCoverArticles,
        res_coverArticles,
        res_libraryArticles,
        res_communityArticles,
        res_discoverArticles,
        res_quote,
    ]);

    // const fetchData = async (
    //     url: string,
    //     setStateFunction: any,
    //     randomize: boolean = false,
    //     parseArticles: boolean = false
    // ) => {
    //     let results: any = await fetchAPI(url);

    //     if (randomize && randomize === true) {
    //         results = results.sort(() => 0.5 - Math.random());
    //     }

    //     if (parseArticle && parseArticles === true) {
    //         // Parsing
    //         results.map((article: any) => {
    //             article = parseArticle(article, AppContext.variant);
    //         });
    //     }

    //     if (typeof setStateFunction !== "undefined") {
    //         setStateFunction(results);
    //         //typeof window !== "undefined" window.mainLazyLoad();
    //     }
    // };

    // useEffect(() => {
    //     fetchData(
    //         "/articles?featured=true&cover_ne=true&homepage_cover_ne=true&_sort=id:DESC&_limit=27&hidden=false",
    //         (data: any) => {
    //             setFeatiredArticles(data);
    //             AppContext.setSuggestedArticles(data);
    //         },
    //         true,
    //         true
    //     );
    //     fetchData(
    //         "/articles?homepage_cover=true&_sort=id:ASC&_limit=3&hidden=false",
    //         setHomepageCoverArticles,
    //         true,
    //         true
    //     );
    //     fetchData(
    //         "/articles?homepage_cover_ne=true&cover=true&_sort=id:ASC&_limit=2&hidden=false",
    //         setCoverArticles,
    //         true,
    //         true
    //     );
    //     fetchData(
    //         "/articles?cover_ne=true&homepage_cover_ne=true&featured_ne=true&_sort=id:DESC&_limit=3&hidden=false",
    //         setLibraryArticles,
    //         false,
    //         true
    //     );
    //     fetchData(
    //         "/articles?section.slug=community&_sort=id:DESC&_limit=3&hidden=false",
    //         setCommunityArticles,
    //         false,
    //         true
    //     );
    //     fetchData(
    //         "/articles?discover=true&_sort=id:DESC&_limit=9&hidden=false",
    //         setDiscoverArticles,
    //         true,
    //         true
    //     );

    //     fetchData(
    //         "/sections",
    //         (sections: any) => {
    //             let quotes: any = [],
    //                 creators: any = [];
    //             //console.info("sections!!!!", sections);
    //             sections.map((section: any) => {
    //                 if (
    //                     section.quote_1_text &&
    //                     section.quote_1_text.length > 0 &&
    //                     section.quote_1_author &&
    //                     section.quote_1_author.length > 0
    //                 ) {
    //                     quotes.push({
    //                         text: section.quote_1_text,
    //                         author: section.quote_1_author,
    //                         image: getEntityImage(section, "quote_1_image"),
    //                         label: section.name,
    //                     });
    //                 }
    //                 if (
    //                     section.quote_2_text &&
    //                     section.quote_2_text.length > 0 &&
    //                     section.quote_2_author &&
    //                     section.quote_2_author.length > 0
    //                 ) {
    //                     quotes.push({
    //                         text: section.quote_2_text,
    //                         author: section.quote_2_author,
    //                         image: getEntityImage(section, "quote_2_image"),
    //                         label: section.name,
    //                     });
    //                 }
    //                 if (
    //                     section.quote_3_text &&
    //                     section.quote_3_text.length > 0 &&
    //                     section.quote_3_author &&
    //                     section.quote_3_author.length > 0
    //                 ) {
    //                     quotes.push({
    //                         text: section.quote_3_text,
    //                         author: section.quote_3_author,
    //                         image: getEntityImage(section, "quote_3_image"),
    //                         label: section.name,
    //                     });
    //                 }
    //             });

    //             quotes = quotes.sort(() => 0.5 - Math.random());
    //             setQuote(quotes[0]);
    //         },
    //         true
    //     );
    // }, []);

    useEffect(() => {
        AppContext.setSelectedSection(null); // reset the selected section
    }, []);

    // Scrolling
    const [y, setY] = useState(0);
    const handleScroll = useCallback(
        (e) => {
            const window = e.currentTarget;
            setY(window.scrollY);

            if (window.scrollY > MOBILE_SCROLL_TRESHOLD) {
                setTimeout(function () {
                    window.mainLazyLoad();
                }, 100);
            }
        },
        [y]
    );
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);
    // End of scrolling

    useEffect(() => {
        setTimeout(function () {
            window.mainLazyLoad();
        }, 100);
    }, [
        featuredArticles,
        homepagecoverArticles,
        coverArticles,
        libraryArticles,
        communityArticles,
        discoverArticles,
        quote,
    ]);

    return featuredArticles ||
        homepagecoverArticles ||
        coverArticles ||
        libraryArticles ||
        communityArticles ||
        discoverArticles ||
        quote ? (
        <Layout>
            <div className={styles.container}>
                <ZoneThreeColumns
                    color="#FFF180"
                    labelColor="#FF91C1"
                    label="INSPIRATION"
                    mainArticle={
                        homepagecoverArticles && homepagecoverArticles[0]
                            ? {
                                  label:
                                      homepagecoverArticles[0] &&
                                      homepagecoverArticles[0].section?.name,
                                  title:
                                      homepagecoverArticles[0] &&
                                      homepagecoverArticles[0].title,
                                  link:
                                      homepagecoverArticles[0] &&
                                      `/articles/${homepagecoverArticles[0].slug}` +
                                          homepagecoverArticles[0].variant_ext,
                                  image:
                                      homepagecoverArticles[0] &&
                                      getEntityImage(
                                          homepagecoverArticles[0],
                                          "square_image_a",
                                          isMobile ? "medium" : undefined
                                      ),
                                  announce:
                                      homepagecoverArticles[0] &&
                                      homepagecoverArticles[0].announce,
                                  extra:
                                      homepagecoverArticles[0] &&
                                      homepagecoverArticles[0].text.replace(
                                          /<[^>]+>/g,
                                          ""
                                      ),
                              }
                            : null
                    }
                    leftArticles={
                        !isMobile
                            ? featuredArticles.slice(0, 3)
                            : y > MOBILE_SCROLL_TRESHOLD
                            ? featuredArticles.slice(0, 3)
                            : []
                    }
                    // leftArticles={featuredArticles.slice(0, 3)}
                    rightArticles={featuredArticles.slice(3, 8)}
                />

                {coverArticles[0] ? (
                    <BigArticleZone
                        leftColor="#ff738d"
                        rightColor="#ff91c1"
                        image={getEntityImage(
                            coverArticles[0],
                            "square_image",
                            isMobile ? "medium" : undefined,
                            true
                        )}
                        label={coverArticles[0].section?.name}
                        title={coverArticles[0].title}
                        announce={coverArticles[0].announce}
                        extra={coverArticles[0].text.replace(/<[^>]+>/g, "")}
                        link={
                            "/articles/" +
                            coverArticles[0].slug +
                            coverArticles[0].variant_ext
                        }
                        track="HOMEPAGE_BIG_ARTICLE"
                    />
                ) : null}
                {/* {quote ? (
                    <QuoteZone
                        quote={quote.text}
                        name={quote.author}
                        // image={quote.image}
                        // label={quote.label}
                    />
                ) : null} */}

                <GridZone
                    label={`Discover`}
                    articles={discoverArticles}
                    track="HOMEPAGE_DISCOVER"
                />
                {coverArticles[1] ? (
                    <BigArticleZone
                        leftColor="#EE8183"
                        rightColor="#EE8183"
                        image={getEntityImage(
                            coverArticles[1],
                            "square_image",
                            isMobile ? "medium" : undefined
                        )}
                        label={coverArticles[1].section?.name}
                        title={coverArticles[1].title}
                        announce={coverArticles[1].announce}
                        extra={coverArticles[1].text.replace(/<[^>]+>/g, "")}
                        link={
                            "/articles/" +
                            coverArticles[1].slug +
                            coverArticles[1].variant_ext
                        }
                        track="HOMEPAGE_SECOND_COVER_ARTICLE"
                    />
                ) : null}
                <ShortGridZone
                    label="Library"
                    trapezeImage="/shortgridtrapeze_pink.svg"
                    articles={libraryArticles}
                    seeallLink={`/library`}
                    track="HOMEPAGE_LIBRARY_ITEM"
                    trackAll="HOMEPAGE_LIBRARY_ALL"
                />

                <ShortGridZone
                    label="Community"
                    color="#FFF280"
                    trapezeImage="/shortgridtrapeze_rose.svg"
                    articles={communityArticles}
                    seeallLink={`/sections/community`}
                    track="HOMEPAGE_COMMUNITY_ITEM"
                    trackAll="HOMEPAGE_COMMUNITY_ALL"
                />
            </div>
        </Layout>
    ) : null;
};

// Static Props -----------------------------------
// This also gets called at build time
export async function getStaticProps() {
    const allArticles: any = await fetchAPI(`/articles?hidden=false`);
    // console.log("allArticles => " + allArticles.length);
    const allSections: any = await fetchAPI(`/sections`);
    // ------------------------------
    // Featured
    const featuredArticles: any = allArticles
        .filter(
            (article: any) =>
                article.featured === true &&
                article.cover !== true &&
                article.homepage_cover !== true
        )
        .sort((a: any, b: any) => a.id - b.id)
        .sort(() => 0.5 - Math.random()) // randomize
        .slice(0, 27);
    // ------------------------------
    // Homepage Cover
    const homepageCoverArticles: any = allArticles
        .filter((article: any) => article.homepage_cover === true)
        .sort((a: any, b: any) => a.id - b.id)
        .sort(() => 0.5 - Math.random()) // randomize
        .slice(0, 3);
    // ------------------------------
    // Cover
    const coverArticles: any = allArticles
        .filter(
            (article: any) =>
                article.cover === true && article.homepage_cover !== true
        )
        .sort((a: any, b: any) => a.id - b.id)
        .sort(() => 0.5 - Math.random()) // randomize
        .slice(0, 2);
    // ------------------------------
    // Library Articles
    const libraryArticles: any = allArticles
        .filter(
            (article: any) =>
                article.cover !== true &&
                article.homepage_cover !== true &&
                article.featured !== true
        )
        .sort((a: any, b: any) => a.id - b.id)
        .slice(0, 3);
    // ------------------------------
    // Community articles
    const communityArticles: any = allArticles
        .filter((article: any) => article.section.slug === "community")
        .sort((a: any, b: any) => a.id - b.id)
        .slice(0, 3);
    // ------------------------------
    // Discover
    const discoverArticles: any = allArticles
        .filter((article: any) => article.discover === true)
        .sort((a: any, b: any) => a.id - b.id)
        .sort(() => 0.5 - Math.random()) // randomize
        .slice(0, 9);
    // ------------------------------
    // Quotes
    let quotes: any = [];
    allSections.map((section: any) => {
        if (section.quote_1_text && section.quote_1_text.length > 0) {
            quotes.push({
                text: section.quote_1_text,
                author: section.quote_1_author,
                image: getEntityImage(section, "quote_1_image"),
                label: section.name,
            });
        }
        if (section.quote_2_text && section.quote_2_text.length > 0) {
            quotes.push({
                text: section.quote_2_text,
                author: section.quote_2_author,
                image: getEntityImage(section, "quote_2_image"),
                label: section.name,
            });
        }
        if (section.quote_3_text && section.quote_3_text.length > 0) {
            quotes.push({
                text: section.quote_3_text,
                author: section.quote_3_author,
                image: getEntityImage(section, "quote_3_image"),
                label: section.name,
            });
        }
    });

    quotes = quotes.sort(() => 0.5 - Math.random());

    // console.log("res_featuredArticles > " + featuredArticles.length);
    // console.log("res_homepageCoverArticles > " + homepageCoverArticles.length);
    // console.log("res_coverArticles > " + coverArticles.length);
    // console.log("res_libraryArticles > " + libraryArticles.length);
    // console.log("res_communityArticles > " + communityArticles.length);
    //console.log("res_discoverArticles > " + discoverArticles.length);
    //console.log("res_quotes > " + quotes.length);

    return {
        props: {
            // props of your component
            // allArticles,
            res_featuredArticles: featuredArticles,
            res_homepageCoverArticles: homepageCoverArticles,
            res_coverArticles: coverArticles,
            res_libraryArticles: libraryArticles,
            res_communityArticles: communityArticles,
            res_discoverArticles: discoverArticles,
            res_quote: quotes.length > 0 ? quotes[0] : null,
        },
    };
}

export default Home;
