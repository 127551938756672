import { trackEvent } from "lib/basic";
import Link from "next/link";

const MAINARTICLE_ANNOUNCE_MINCHARS = 1;

export interface Props {
    leftColor?: any;
    rightColor?: any;
    image?: any;
    label?: any;
    title?: any;
    announce?: any;
    link?: any;
    extra?: any;
    nolazy?: boolean;
    track?: string;
}

export default function BigArticleZone({
    leftColor,
    rightColor,
    image,
    label,
    title,
    announce,
    link = "/",
    extra,
    nolazy,
    track,
}: Props): JSX.Element {
    const articleImageClassName =
        nolazy && nolazy === true ? "ltx_Image" : "ltx_Image lazy";

    return (
        <Link href={link} passHref>
            <a
                onClick={() => {
                    if (track) {
                        trackEvent(track);
                    }
                }}
            >
                <div className="ltx-BigArticleZone">
                    <div className="ltx_Container">
                        <div
                            className={articleImageClassName}
                            style={{
                                backgroundColor: leftColor,
                                backgroundImage: `url(${image})`,
                            }}
                        ></div>
                        <div style={{ backgroundColor: rightColor }}>
                            <div className="ltx_Label">{label}</div>
                            <div className="ltx_Title">{title}</div>

                            {announce &&
                            announce.length >= MAINARTICLE_ANNOUNCE_MINCHARS ? (
                                <div className="ltx_Announce">{announce}</div>
                            ) : null}

                            {!announce ||
                            announce.length < MAINARTICLE_ANNOUNCE_MINCHARS ? (
                                <div
                                    className="ltx_Announce"
                                    dangerouslySetInnerHTML={{
                                        __html: extra,
                                    }}
                                ></div>
                            ) : null}

                            <div className="ltx_ArrowLink ltx_LeftAlign">
                                <i />
                                Read More
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </Link>
    );
}
