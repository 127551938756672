import Link from "next/link";
import { getEntityImage, trackEvent } from "lib/basic";
import { useState, useEffect } from "react";
import { useAppContext } from "./../context/state";
import Image from "next/image";

const ARTICLES_LIMIT = 9;

const chunkify = (a: any, n: any, balanced: any) => {
    if (n < 2) return [a];

    var len = a.length,
        out = [],
        i = 0,
        size;

    if (len % n === 0) {
        size = Math.floor(len / n);
        while (i < len) {
            out.push(a.slice(i, (i += size)));
        }
    } else if (balanced) {
        while (i < len) {
            size = Math.ceil((len - i) / n--);
            out.push(a.slice(i, (i += size)));
        }
    } else {
        n--;
        size = Math.floor(len / n);
        if (len % size === 0) size--;
        while (i < size * n) {
            out.push(a.slice(i, (i += size)));
        }
        out.push(a.slice(size * n));
    }

    return out;
};

const ArticleBox = ({ article, track }: any) => {
    const AppContext = useAppContext();

    const className =
        article &&
        article.transparent_square &&
        article.transparent_square === true
            ? "ltx_ArticleBox ltx_Transparent"
            : "ltx_ArticleBox";

    return (
        <Link href={article ? article.parsed_url : `#`} passHref>
            <a
                onClick={() => {
                    if (track) {
                        trackEvent(track);
                    }
                }}
            >
                <div className={className}>
                    {/* <div
                    className="ltx_Image"
                    style={{
                        backgroundImage:
                            article && article.square_image_a
                                ? `url(${getEntityImage(
                                      article,
                                      "square_image_a",
                                      "thumbnail"
                                  )})`
                                : "",
                        backgroundColor: "transparent",
                    }}
                /> */}
                    {article?.square_image && (
                        <Image
                            // alt={AppContext.defaultAlt}
                            alt={article.title}
                            src={getEntityImage(
                                article,
                                "square_image",
                                "thumbnail"
                            )}
                            width={262}
                            height={262}
                            className="ltx_Image"
                        />
                    )}

                    <div className="ltx_Title">{article && article.title}</div>
                    <div className="ltx_ArrowLink">
                        <i />
                    </div>
                </div>
            </a>
        </Link>
    );
};

export default function GridZone({
    color,
    label,
    articles,
    track,
}: any): JSX.Element {
    const className = !label ? "ltx-GridZone" : "ltx-GridZone ltx_Section";

    if (typeof articles === "undefined") articles = [{}, {}, {}];

    const [limit, setLimit] = useState(true);
    const [columnsArticles, setColumnsArticles] = useState<Array<any>>([
        {},
        {},
        {},
    ]);
    //console.info("GRID articles", articles);
    // const columnsArticles = chunkify(articles, 3, true);
    //console.info("GRID columnsArticles", columnsArticles);

    useEffect(() => {
        setColumnsArticles(
            limit
                ? chunkify(articles.slice(0, ARTICLES_LIMIT), 3, true)
                : chunkify(articles, 3, true)
        );
    }, [articles, limit]);

    useEffect(() => {
        setLimit(true);
    }, [articles]);

    return (
        <div
            className={className}
            style={{ backgroundColor: color ? color : null }}
        >
            {label ? (
                <div className="ltx_Container">
                    <div className="txt_HomePageTitle text-center mb-8">
                        {label}
                    </div>
                </div>
            ) : null}
            <div className="ltx_Container">
                <div>
                    {!label ? (
                        <div className="txt_HomePageTitle text-center mb-8">
                            How To
                        </div>
                    ) : null}
                    {columnsArticles &&
                        columnsArticles[0] &&
                        columnsArticles[0].length > 0 &&
                        columnsArticles[0].map((article: any) => (
                            <ArticleBox
                                article={article}
                                key={article.id}
                                track={track}
                            />
                        ))}

                    {!label ? (
                        <a className="ltx_ArrowLinkRed">SEE ALL ...</a>
                    ) : null}
                </div>
                <div>
                    {!label ? (
                        <div className="txt_HomePageTitle text-center mb-24">
                            Library
                        </div>
                    ) : null}
                    {columnsArticles &&
                        columnsArticles[1] &&
                        columnsArticles[1].length > 0 &&
                        columnsArticles[1].map((article: any) => (
                            <ArticleBox
                                article={article}
                                key={article.id}
                                track={track}
                            />
                        ))}

                    {!label ? (
                        <Link href={"/library/"} passHref>
                            <a className="ltx_ArrowLinkRed">SEE ALL LIBRARY</a>
                        </Link>
                    ) : null}
                </div>
                <div>
                    {!label ? (
                        <div className="txt_HomePageTitle text-center mb-8">
                            Inspiration
                        </div>
                    ) : null}
                    {columnsArticles &&
                        columnsArticles[2] &&
                        columnsArticles[2].length > 0 &&
                        columnsArticles[2].map((article: any) => (
                            <ArticleBox
                                article={article}
                                key={article.id}
                                track={track}
                            />
                        ))}

                    {!label ? (
                        <a className="ltx_ArrowLinkRed">SEE ALL INSPIRATION</a>
                    ) : null}
                </div>
            </div>
            {label && limit && articles.length > ARTICLES_LIMIT ? (
                <div className="ltx_Container pb-8">
                    <a
                        className="ltx_ArrowLinkRed"
                        onClick={(e) => setLimit(false)}
                    >
                        SEE ALL
                    </a>
                </div>
            ) : null}
        </div>
    );
}
